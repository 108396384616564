import React, { forwardRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { FormControl, CardHeader, Card, CardContent, Container, OutlinedInput,
  Tabs, Grid, Paper, Tab, InputLabel, MenuItem, Select, FormHelperText, TextField, makeStyles } from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { PRODUCT_DATA } from '../../mockData'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const Table = ({ setSelectedProducts }) => {
  const classes = useStyles();

  const [storeDataFilter, setStoreDataFilter] = React.useState()
  const [categoryFilter, setCategoryFilter] = React.useState('')
  const [producerFilter, setProducerFilter] = React.useState('')
  const [quantityFilter, setQuantityFilter] = React.useState('')
  const [minPriceFilter, setMinPriceFilter] = React.useState('')
  const [maxPriceFilter, setMaxPriceFilter] = React.useState('')

  const [state, setState] = React.useState({
    columns: [
      { title: 'Name', field: 'Demo Name' },
      { title: 'Producer', field: 'Brand' },
      { title: 'Product ID', field: 'AGLC Product ID' },
      { title: 'Category', field: 'Format' },
      { title: 'Species', field: 'Type' },
      { title: 'Price', field: 'AGLC Resale Price', type: 'numeric' },
      { title: 'Quantity', field: 'Quantity', type: 'numeric' },
      { title: 'THC', field: 'THC', type: 'numeric' },
      { title: 'CBD', field: 'CBD', type: 'numeric' },
    ],
  });

  const categories = useMemo(() => {
    const tempProducts = PRODUCT_DATA.map(product => {
      return product.Format
    })
    return Array.from(new Set(tempProducts))
  })

  const producers = useMemo(() => {
    const tempProducts = PRODUCT_DATA.map(product => {
      return product.Brand
    })
    return Array.from(new Set(tempProducts))
  })

  const quantity = useMemo(() => {
    const tempQuantity = PRODUCT_DATA.map(product => {
      return product.Quantity
    })
    return Array.from(new Set(tempQuantity))
  })

  const filterData = () => {
    let productData = [...PRODUCT_DATA]
    if (categoryFilter) {
      productData = productData.filter(product => {
        return product.Format == categoryFilter
      })
    }
    if (producerFilter) {
      productData = productData.filter(product => {
        return product.Brand == producerFilter
      })
    }
    if (storeDataFilter) {
      productData = productData.filter(product => {
        return product["Stocked by User?"] == 'Yes'
      })
    }
    if (quantityFilter) {
      productData = productData.filter(product => {
        return product.Quantity == quantityFilter
      })
    }
    if (minPriceFilter) {
      productData = productData.filter(product => {
        return product["AGLC Resale Price"] >= minPriceFilter
      })
    }
    if (maxPriceFilter) {
      productData = productData.filter(product => {
        return product["AGLC Resale Price"] <= maxPriceFilter
      })
    }
    return productData
  }

  return (
    <Grid container spacing={3}>
      <Grid item item xs={10} xl={10}>
        <Card>
          <CardHeader title="Select Graphed Products" className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
          <MaterialTable
            icons={tableIcons}
            title="Products"
            columns={state.columns}
            data={filterData()}
            options={{
              selection: true,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            onSelectionChange={(rows) => setSelectedProducts(rows.slice(0))}
          />
        </Card>
      </Grid>
      <Grid item xs={2} xl={2}>
        <Card className={classes.graphSidebar}>
          <CardHeader title="Filter Table" className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
          <CardContent className={classes.marketDataToggle}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Stocked in Store</InputLabel>
              <Select
                value={storeDataFilter}
                label="Stocked in Store"
                onChange={e => {
                  setStoreDataFilter(e.target.value)
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </CardContent>
          <CardContent className={classes.marketDataToggle}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Category</InputLabel>
              <Select
                value={categoryFilter}
                label="Category"
                onChange={e => {
                  setCategoryFilter(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {categories.map(category => {
                  return <MenuItem key={category} value={category}>{category}</MenuItem>
                })}
              </Select>
            </FormControl>
          </CardContent>
          <CardContent className={classes.marketDataToggle}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Producer</InputLabel>
              <Select
                value={producerFilter}
                label="Producer"
                onChange={e => {
                  setProducerFilter(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {producers.map(producer => {
                  return <MenuItem key={producer} value={producer}>{producer}</MenuItem>
                })}
              </Select>
            </FormControl>
          </CardContent>
          <CardContent className={classes.marketDataToggle}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Quantity</InputLabel>
              <Select
                value={quantityFilter}
                label="Quantity Filter"
                onChange={e => {
                  setQuantityFilter(e.target.value);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {quantity.map(quantity => {
                  return <MenuItem key={quantity} value={quantity}>{quantity}</MenuItem>
                })}
              </Select>
            </FormControl>
          </CardContent>
          <CardContent className={classes.marketDataToggle}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Min Price</InputLabel>
              <OutlinedInput
                value={minPriceFilter}
                label="Min Price"
                type="number"
                onChange={e => {
                  setMinPriceFilter(e.target.value);
                }}
              >
              </OutlinedInput>
            </FormControl>
          </CardContent>
          <CardContent className={classes.marketDataToggle}>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Max Price</InputLabel>
              <OutlinedInput
                value={maxPriceFilter}
                label="Max Price"
                type="number"
                onChange={e => {
                  setMaxPriceFilter(e.target.value);
                }}
              >
              </OutlinedInput>
            </FormControl>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  graphSidebar: {
    height: '100%',
  },
  sidebarSeparator: {
    borderColor: 'rgba(0,0,0,.3)',
  },
  cardHeader: {
    backgroundColor: '#262A2E',
    textAlign: 'center',
  },
}));

export default Table