import React from 'react';
import AuthContext from './context';

import { withFirebase } from '../firebase';
import { withRouter } from 'react-router-dom';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push('/');
          }
        },
        () => this.props.history.push('/login'),
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthContext.Consumer>
      );
    }
  }
  return withRouter(withFirebase(WithAuthorization))
};

export default withAuthorization;
