import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core';

const Error = ({ location }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      {location.pathname.includes('/500') ?
        <>
          <h1 className="float-left display-3 mr-4">500</h1>
          <h4 className="pt-3">Houston, we have a problem!</h4>
          <p className="text-muted float-left">The page you are looking for is temporarily unavailable.</p>
        </> :
        <>
          <h1 className="float-left display-3 mr-4">404</h1>
          <h4 className="pt-3">Oops! You're lost.</h4>
          <p className="text-muted float-left">The page you are looking for was not found.</p>
        </>
      }
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default Error;

{/* <InputGroup className="input-prepend">
<InputGroupAddon addonType="prepend">
  <InputGroupText>
    <i className="fa fa-search"></i>
  </InputGroupText>
</InputGroupAddon>
<Input size="16" type="text" placeholder="What are you looking for?" />
<InputGroupAddon addonType="append">
  <Button color="info">Search</Button>
</InputGroupAddon>
</InputGroup> */}