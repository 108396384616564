import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { Container, makeStyles } from '@material-ui/core';

import Drawer from './Drawer.js' 

// pages
import Signup from '../../pages/Signup';
import Login from '../../pages/Login';
import Forgot from '../../pages/Forgot';
import Error from '../../pages/Error';
import Home from '../../pages/Home';

import Dashboard from '../../pages/Dashboard';

import { withAuthenticationConsumer } from '../../session';
import { withFirebase } from '../../firebase';

const Main = ({ firebase, authUser }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <main className={classes.content}>
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
      <Container maxWidth={false} 
        className={clsx(classes.container, !drawerOpen && classes.drawerCollapsed)}>
        <Switch>

          {/* <Route exact path='/o/:orgId/menus/:menuId' component={ViewMenu} /> */}

          <Route exact path='/signup' component={Signup} />

          <Route exact path='/login' component={Login} />

          <Route exact path='/forgot' component={Forgot} />

          <Route exact path='/dashboard' component={Dashboard} />

          <Route exact path='/' component={Home} />

          <Route path='/' component={Error} />
        </Switch>
      </Container>
    </main>
  );
}

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100vh',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center', /* aligns items vertically */
    justifyContent: 'center', /* aligns items horizontally */
    marginLeft: '200px'
  },
  drawerCollapsed: {
    marginLeft: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9),
    },
  },
}));

export default withFirebase(withAuthenticationConsumer(Main))
