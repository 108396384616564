import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label, Legend, ResponsiveContainer
} from 'recharts';
import { Card, CardContent, Container, Tabs, Grid, Paper, Tab, makeStyles, useTheme } from '@material-ui/core';

import { blue } from '@material-ui/core/colors';

import { GROWTH_DATA_PRODUCT, GROWTH_DATA_CATEGORY, UNIT_SALES_DATA, UNIT_SALES_CATEGORY_DATA } from '../../mockData'
import { PRODUCT_DATA } from '../../mockData'

const BarGraphData = [[UNIT_SALES_DATA, UNIT_SALES_CATEGORY_DATA], [GROWTH_DATA_PRODUCT, GROWTH_DATA_CATEGORY]]

const PERIODS = ['7day', '14day', '30day', '90day']
const STORE_PERIODS = ['7day_store', '14day_store', '30day_store', '90day_store']

const BarGraph = ({ products, flipAxis, showStoreData, selectedMetric, selectedSubMetric, selectedPeriod }) => {
  const theme = useTheme();
  const classes = useStyles();

  const productsMap = () => {
    const tempProductsMap = {}
    PRODUCT_DATA.forEach(product => {
      tempProductsMap[product['AGLC Product ID']] = {
        name: product['Demo Name']
      }
    })
    return tempProductsMap
  }

  const defaultData = () => {
    return BarGraphData[selectedMetric][selectedSubMetric].sort((a, b) => {
      const tempSelectedPeriod = PERIODS[selectedPeriod]
      return b[tempSelectedPeriod] - a[tempSelectedPeriod]
    }).slice(0,10).map(data => {
      data['name'] = productsMap()[data.Product_ID].name
      return data
    })
  }

  const tableData = () => {
    const tempProductsMap = {}
    products.forEach(product => {
      tempProductsMap[product['AGLC Product ID']] = {
        name: product['Demo Name']
      }
    })
    return BarGraphData[selectedMetric][selectedSubMetric].filter(data => {
      return tempProductsMap[data.Product_ID]
    }).map(data => {
      data['name'] = tempProductsMap[data.Product_ID].name
      return data
    })
  }
  
  const graphData = () => {
    const tempTableData = tableData()
    if (tempTableData.length) {
      return tempTableData
    }
    return defaultData()
  }

  return (<>
    <ResponsiveContainer width='100%' height={800}>
      <BarChart
          layout={showStoreData ? 'horizontal' : 'vertical'}
          data={graphData()}
          margin={{top: 20, right: 20, left: 20, bottom: 10}}
        >
          {!showStoreData && <XAxis type="number" tick={{ fill: 'white' }} />}
          {!showStoreData && <YAxis type="category" dataKey="name" tick={{ fill: 'white' }} width={120} />}
          {showStoreData && <YAxis type="number" tick={{ fill: 'white' }} />}
          {showStoreData && <YAxis yAxisId="right" orientation="right" type="number" tick={{ fill: 'white' }} />}
          {showStoreData && <XAxis type="category" dataKey="name" tick={{ fill: 'white' }} width={120} />}
          <Tooltip 
            contentStyle={{ backgroundColor: 'rgba(0,0,0,.8)' }}
            cursor={{ fill: 'rgba(0,0,0,.3)' }}
            />
          <Bar dataKey={PERIODS[selectedPeriod]} fill={theme.palette.primary.light} name="Market Level" />
          {showStoreData && <Bar yAxisId="right" dataKey={STORE_PERIODS[selectedPeriod]} fill={theme.palette.primary.dark} name="Store Level" />}
          {showStoreData && <Legend layout= 'vertical' align = "right" verticalAlign="top"/>}
          <CartesianGrid strokeDasharray="3 3"/>
        </BarChart>
    </ResponsiveContainer>
  </>)
}

const useStyles = makeStyles((theme) => ({
}));

export default BarGraph