import React, { Component, useEffect, useState } from 'react';
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'

import { withFirebase } from '../../../firebase';

const SignupForm = ({ firebase, history }) => {
  const classes = useStyles();

  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [firstNameField, setFirstNameField] = React.useState('');
  const [lastNameField, setLastNameField] = React.useState('');
  const [emailField, setEmailField] = React.useState('');
  const [passwordField, setPasswordField] = React.useState('');
  const [confirmPasswordField, setConfirmPasswordField] = React.useState('');
  const [acceptedTermsField, setAcceptedTermsField] = React.useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setButtonLoading(true)

    const firstName = firstNameField;
    const lastName = lastNameField;
    const email = emailField;
    const password = passwordField;
    const acceptedTerms = acceptedTermsField;
    const role = 'user'
    let avatar = 'https://firebasestorage.googleapis.com/v0/b/spna-cannabis.appspot.com/o/users%2Favatars%2Fdefault.png?alt=media&token=ff28e8fa-0b54-47fa-9b53-9aba14bac506'
    firebase.doCreateUserWithEmailAndPassword(email, password)
    .then(authUser => { // create the initial user minus username
      return firebase.user(authUser.user.uid).set({
          firstName,
          lastName,
          email,
          role,
          avatar,
          acceptedTerms
        }
      );
    })
    .then(() => {
      return firebase.doSendEmailVerification();
    })
    .then(authUser => {
      history.push('/')
    })
    .catch(error => {
      setError(error);
    })
    .then(() => {
      setButtonLoading(false)
    })
  }

  const isValid = () => {
    if (!emailField || !acceptedTermsField || passwordField != confirmPasswordField) {
      return false
    }
    return true
  }

  return (
    <form className={classes.form} onSubmit={onSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="firstName"
            variant="outlined"
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            value={firstNameField}
            onChange={(e) => {setFirstNameField(e.target.value)}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lname"
            value={lastNameField}
            onChange={(e) => {setLastNameField(e.target.value)}}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={emailField}
            onChange={(e) => {setEmailField(e.target.value)}}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={passwordField}
            onChange={(e) => {setPasswordField(e.target.value)}}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            fullWidth
            name="confirm_password"
            label="Confirm Password"
            type="password"
            id="confirm_password"
            value={confirmPasswordField}
            onChange={(e) => {setConfirmPasswordField(e.target.value)}}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox value="allowExtraEmails" color="primary" />}
            value={acceptedTermsField}
            onChange={(e) => {setAcceptedTermsField(e.target.checked)}}
          />
          I accept the <Link href="https://www.iubenda.com/terms-and-conditions/15834679" target="_blank" >Terms and Conditions</Link>
        </Grid>
      </Grid>
      {error &&
        <Alert variant="outlined" severity="error">
          {error.message}
        </Alert>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isValid() || buttonLoading}
        className={classes.submit}
      >
        {buttonLoading ? 'Signing up...' : 'Sign Up'}
      </Button>
      <Grid container justify="flex-end">
        <Grid item>
          <Link component={RouterLink} to="/login">
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default withRouter(withFirebase(SignupForm))