import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { render } from "react-dom";
import { FormControl, FormGroup, FormControlLabel, Switch, CardHeader, Card, CardContent, Container, 
  Tabs, Grid, Paper, Tab, makeStyles } from '@material-ui/core';

import { withAuthorization } from '../../session';
import BarGraph from './BarGraph'
import LineGraph from './LineGraph'
import Table from './Table'

const METRICS = [
  {name: 'Unit Sales', disabled: false},
  {name: 'Sales Growth', disabled: false},
  {name: 'Product Rating', disabled: true},
  {name: 'THC/CBD Lvls', disabled: false},
]
const SALES_METRICS = [
  {name: 'Unit', disabled: false}, 
  {name: 'Percentage of Category', disabled: false},
  {name: 'Turnover Time', disabled: true},
]
const GROWTH_METRICS = [
  {name: 'Product', disabled: false}, 
  {name: 'Compared to Category', disabled: false},
]

const PERIODS = [
  {name: '1 Week', disabled: false}, 
  {name: '2 Week', disabled: false}, 
  {name: '30 Day', disabled: false},
  {name: '90 Day', disabled: false},
]
const LINE_PERIODS = [
  {name: '30 Day', disabled: false}, 
  {name: '90 Day', disabled: false}, 
  {name: '180 Day', disabled: false},
  {name: '365 Day', disabled: false},
]

const Dashboard = ({  }) => {
  const classes = useStyles();
  const [showStoreData, setShowStoreData] = React.useState(false)
  const [flipAxis, setFlipAxis] = React.useState(false)
  const [selectedMetric, setSelectedMetric] = React.useState(1)
  const [selectedSalesMetric, setSelectedSalesMetric] = React.useState(0)
  const [selectedGrowthMetric, setSelectedGrowthMetric] = React.useState(0)
  const [selectedPeriod, setSelectedPeriod] = React.useState(0)

  const [selectedProducts, setSelectedProducts] = React.useState([])

  const graphHeading = () => {
    let appendString = selectedProducts.length ? '' : ' (Top 10 Products)'
    if (selectedMetric == 0) {
      if (selectedSalesMetric == 0) {
        return `${PERIODS[selectedPeriod].name} ${METRICS[selectedMetric].name}` + appendString
      }
      if (selectedSalesMetric == 1) {
        return `${PERIODS[selectedPeriod].name} ${METRICS[selectedMetric].name} as a ${SALES_METRICS[selectedSalesMetric].name}` + appendString
      }
      return `${PERIODS[selectedPeriod].name} ${SALES_METRICS[selectedSalesMetric].name} ${METRICS[selectedMetric].name}` + appendString
    }
    if (selectedMetric == 1) {
      if (selectedGrowthMetric == 1) {
        return `${PERIODS[selectedPeriod].name} Product ${METRICS[selectedMetric].name} Relative to Category` + appendString
      }
      return `${PERIODS[selectedPeriod].name} ${GROWTH_METRICS[selectedGrowthMetric].name} ${METRICS[selectedMetric].name}` + appendString
    }
    return `${LINE_PERIODS[selectedPeriod].name} ${METRICS[selectedMetric].name}`
  }

  return (
    <Container maxWidth={false}>
      {/* Metric Selector */}
      <Grid container spacing={3}>
        <Grid item xs={1} style={{paddingRight: '0px'}}>  
          <Card style={{borderRadius: '4px 0 0 4px'}}>
            <CardHeader title='Select Metric' className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
          </Card>
        </Grid>
        <Grid item xs={11} style={{paddingLeft: '0px'}}> 
          <Card style={{borderRadius: '0 4px 4px 0'}}>
            <Tabs
              value={selectedMetric}
              onChange={(e, i) => setSelectedMetric(i)}
              classes={{indicator: classes.tabSelectorIndicator}}
              centered
            >
              {METRICS.map((label, index) => {
                return <Tab
                        key={index}
                        label={label.name} 
                        disabled={label.disabled} 
                        className={classes.tabText}
                      />
              })}
            </Tabs>
          </Card>
        </Grid>
        {selectedMetric == 0 && <>
          <Grid item xs={1} style={{paddingRight: '0px', paddingTop: '0px'}}>
            <Card style={{borderRadius: '4px 0 0 4px'}}>
              <CardHeader title='Variant' className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
            </Card>
          </Grid>
          <Grid item xs={11} style={{paddingTop: '0px', paddingLeft: '0px'}}> 
            <Card style={{borderRadius: '0 4px 4px 0'}}>
              <Tabs
                value={selectedSalesMetric}
                onChange={(e, i) => setSelectedSalesMetric(i)}
                classes={{indicator: classes.tabSelectorIndicator}}
                centered
              >
                {SALES_METRICS.map((label, index) => {
                  return <Tab
                          key={index}
                          label={label.name} 
                          disabled={label.disabled}
                          className={classes.tabText}
                        />
                })}
              </Tabs>
            </Card>
          </Grid>
        </>}
        {selectedMetric == 1 && <>
          <Grid item xs={1} style={{paddingRight: '0px', paddingTop: '0px'}}>  
            <Card style={{borderRadius: '4px 0 0 4px'}}>
              <CardHeader title='Variant' className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
            </Card>
          </Grid>
          <Grid item xs={11} style={{paddingTop: '0px', paddingLeft: '0px'}}> 
            <Card style={{borderRadius: '0 4px 4px 0'}}>
              <Tabs
                value={selectedGrowthMetric}
                onChange={(e, i) => setSelectedGrowthMetric(i)}
                classes={{indicator: classes.tabSelectorIndicator}}
                centered
              >
                {GROWTH_METRICS.map((label, index) => {
                  return <Tab
                          key={index}
                          label={label.name} 
                          disabled={label.disabled} 
                          className={classes.tabText}
                        />
                })}
              </Tabs>
            </Card>
          </Grid>
        </>}
      </Grid>
      {/* Graph and Period Selector */}
      <Grid container spacing={3}>
        <Grid item xs={10} xl={10} >
          <Card>
            <CardHeader title={graphHeading()} className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
            <CardContent>
              {selectedMetric == 0 && <BarGraph 
                products={selectedProducts} 
                showStoreData={showStoreData}
                flipAxis={flipAxis}
                selectedMetric={selectedMetric} 
                selectedSubMetric={selectedSalesMetric}
                selectedPeriod={selectedPeriod}
              />}
              {selectedMetric == 1 && <BarGraph 
                products={selectedProducts} 
                showStoreData={showStoreData}
                flipAxis={flipAxis}
                selectedMetric={selectedMetric} 
                selectedSubMetric={selectedGrowthMetric} 
                selectedPeriod={selectedPeriod}
              />}
              {selectedMetric == 3 && <LineGraph 
                products={selectedProducts} 
                showStoreData={showStoreData}
                selectedMetric={selectedMetric} 
                selectedPeriod={selectedPeriod}
              />}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2} xl={2}>
          <Card className={classes.graphSidebar}>
            <CardHeader title="Select Time Period" className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedPeriod}
                onChange={(e, i) => setSelectedPeriod(i)}
                classes={{indicator: classes.tabSelectorIndicator}}
              >
              {selectedMetric !== 3 && PERIODS.map((label, index) => {
                return <Tab
                        key={index}
                        label={label.name} 
                        disabled={label.disabled} 
                        className={classes.periodTab}
                      />
              })}
              {selectedMetric === 3 && LINE_PERIODS.map((label, index) => {
                return <Tab
                        key={index}
                        label={label.name} 
                        disabled={label.disabled} 
                        className={classes.periodTab}
                      />
              })}
            </Tabs>
          </Card>

          <Card className={classes.graphSidebar}>
            <CardHeader title="Store Data" className={classes.cardHeader} titleTypographyProps={{variant:'h6'}} />
            <CardContent className={classes.marketDataToggle}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={<Switch
                      checked={showStoreData}
                      onChange={(e) => {setShowStoreData(e.target.checked)}}
                    />}
                  />
                </FormGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Table */}
      <Table setSelectedProducts={setSelectedProducts}/>
    </Container>
  )
}

const condition = authUser => {
  // if (authUser && authUser.role === 'subscriber') {
  if (authUser) {
    return true
  } else {
    return false
  }
}

const useStyles = makeStyles((theme) => ({
  tabSelectorIndicator: {
    backgroundColor: theme.palette.primary.light,
  },
  periodTab: {
    maxWidth: '100%',
    fontSize: '1rem',
    letterSpacing: '0.04rem',
  },
  graphSidebar: {
    marginBottom: '12px',
    // height: '100%',
  },
  sidebarSeparator: {
    borderColor: 'rgba(0,0,0,.3)',
  },
  marketDataToggle: {
    display: 'flex',
    justifyContent: 'center'
  },
  cardHeader: {
    backgroundColor: '#262A2E',
    textAlign: 'center',
    maxHeight: '48px',
  },
  tabText: {
    maxWidth: '100%',
    fontSize: '1rem',
    letterSpacing: '0.04rem'
  }
}));

export default withAuthorization(condition)(Dashboard)