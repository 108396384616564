import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Label, Legend, ResponsiveContainer
} from 'recharts';
import { Card, CardContent, Container, Tabs, Grid, Paper, Tab, makeStyles, useTheme } from '@material-ui/core';

import { THC_DATA } from '../../mockData'
import { PRODUCT_DATA } from '../../mockData'

const PERIODS = ['30', '90', '180', '365']

const LineGraph = ({ products, selectedMetric, selectedPeriod }) => {
  const theme = useTheme();
  const classes = useStyles();

  const productsMap = () => {
    const tempProductsMap = {}
    PRODUCT_DATA.forEach(product => {
      tempProductsMap[product['AGLC Product ID']] = {
        name: product['Demo Name']
      }
    })
    return tempProductsMap
  }

  const firstProduct = () => {
    return Object.keys(productsMap()).slice(0,1)[0]
  } 

  const defaultData = () => {
    const filteredDateTHCData = THC_DATA.sort((a, b) => {
      return moment(a.Date_Range).diff(b.Date_Range)
    }).filter(date => {
      const dayDiff = moment('2020-05-24').diff(date.Date_Range, 'days')
      const withinPeriod = PERIODS[selectedPeriod] // within this many days
      return dayDiff < withinPeriod
    })
    return filteredDateTHCData
  }

  const tableData = () => {
    const tempProductsMap = {}
    products.forEach(product => {
      tempProductsMap[product['AGLC Product ID']] = {
        name: product['Demo Name']
      }
    })
    return tempProductsMap
  }
  
  const graphData = () => {
    return defaultData()
  }

  return (<>
    <ResponsiveContainer width='100%' height={800}>
      <LineChart
        data={graphData()}
        margin={{top: 20, right: 20, left: 20, bottom: 10}}
      >
        <XAxis dataKey="Date_Range" tick={{ fill: 'white' }} />
        <YAxis tick={{ fill: 'white'}} label={{ value: 'THC/CBD mg/g', angle: -90, position: 'left', fill: 'white' }} />
        <Tooltip 
          contentStyle={{ backgroundColor: 'rgba(0,0,0,.7)' }}
        />
        <CartesianGrid strokeDasharray="3 3"/>
        <Legend />
        {Object.keys(tableData()).length ? 
          Object.keys(graphData()[0]).map(key => {
            if (key != 'Date_Range' && Object.keys(tableData()).includes(key)) {
              return <Line key={key} type="monotone" dataKey={key} name={productsMap()[key].name} />
            }
          })

        : 
        
          graphData().length && Object.keys(graphData()[0]).map(key => {
            if (key != 'Date_Range' && firstProduct() == key) {
              return <Line key={key} type="monotone" dataKey={key} name={productsMap()[key].name} />
            }
          })
        }
        {/* <Line type="monotone" dataKey="Balance by Soleil" stroke="#8884d8" />
        <Line type="monotone" dataKey="Prohibition Pre-Roll" stroke="#82ca9d" /> */}
      </LineChart>
    </ResponsiveContainer>
  </>)
}

const useStyles = makeStyles((theme) => ({
}));

export default LineGraph