import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core'

import './App.css';

// import firebase and auth
import { withAuthenticationProvider } from './session';

// pages
import Main from './containers/Main';

const App = () => {
  const classes = useStyles();

  return (
    <Router>
      <Switch>
        <Route path='/' component={Main}/>
      </Switch>
    </Router>
  );
}

const useStyles = makeStyles((theme) => ({
  // appRoot: {
  //   display: 'flex'
  // }
}));

export default withAuthenticationProvider(App);
