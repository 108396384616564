import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDgivSsSlVOHDFzZdxkCcqGdXb6Djogfy4",
  authDomain: "spna-cannabis.firebaseapp.com",
  databaseURL: "https://spna-cannabis.firebaseio.com",
  projectId: "spna-cannabis",
  storageBucket: "spna-cannabis.appspot.com",
  messagingSenderId: "357109590372",
  appId: "1:357109590372:web:4bdeb3674c8b358e5d3271",
  measurementId: "G-LN27RQCBB3"
};

// Initialize Firebase
class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.storage = app.storage();
    this.storageRef = this.storage.ref();
    this.analytics = app.analytics();
    this.analytics.logEvent('app started up');
    this.url = 'https://us-central1-spna-cannabis.cloudfunctions.net'
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password)
  }

  doSignOut = () => {
    return this.auth.signOut()
  }

  doPasswordReset = email => {
    return this.auth.sendPasswordResetEmail(email)
  }

  doPasswordUpdate = password => {
    return this.auth.currentUser.updatePassword(password)
  }

  doSendEmailVerification = () => {
    return this.auth.currentUser.sendEmailVerification()
  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** Username API ***
  username = (username) => this.db.doc(`usernames/${username}`);

  // *** User API ***
  user = (uid) => this.db.doc(`users/${uid}`);
  users = () => this.db.collection('users');
}

export default Firebase;
