import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, makeStyles } from '@material-ui/core';

const headerStyle = {
  backgroundImage: 'url(assets/img/bgs/search.jpg)',
  backgroundPosition: 'center',
  backgroundSize: 'cover'
};

const Home = () => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent className={classes.paper}>
        <h1 className="display-3">BloomBud | Market Intelligence</h1>
        <p className="lead">Data, Insights and Analytics</p>
        <br></br>
        <p className="lead">
          <Button color="primary" 
          component={Link} to="/signup" 
          variant="outlined">Get Started With Us Today</Button>
        </p>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default Home
