import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { Button, Drawer, List, Typography, Divider, IconButton, Badge, ListItem, 
  ListItemIcon, ListItemText, ListSubheader, makeStyles, useTheme } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import logo from '../../assets/img/logos/bloombudpot.png'
import { withAuthenticationConsumer } from '../../session';
import { withFirebase } from '../../firebase';

const Main = ({ firebase, authUser, drawerOpen, setDrawerOpen }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose) }}
    >
      {/* header */}
      <div>
        <div className={classes.drawerHeader}>
          <Link to="/">
            <div>
              <img className={classes.drawerHeaderLogo} src={logo} alt='App Logo'/>
            </div>
          </Link>
        </div>
        {/* links */}
        <List className={classes.drawerList}>
          <ListItem button component={NavLink} to="/dashboard" 
            activeStyle={{ background: theme.palette.primary.light, color: theme.palette.text.primary }}>
              <ListItemIcon>
                <DashboardIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Dashboard" classes={{ primary: classes.listItemText }}/>
          </ListItem>
        </List>
      </div>

      {/* footer */}
      <div>
        <List className={classes.drawerList}>
          {!authUser && <ListItem button component={NavLink} to="/login" 
            activeStyle={{ background: theme.palette.primary.light, color: theme.palette.text.primary }}>
              <ListItemIcon>
                <ExitToAppIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Login" classes={{ primary: classes.listItemText }}/>
          </ListItem>}
          {authUser && <ListItem button component={Link} to="/" 
            onClick={firebase.doSignOut}>
              <ListItemIcon>
                <ExitToAppIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Logout" classes={{ primary: classes.listItemText }}/>
          </ListItem>}
        </List>
        <div className={classes.drawerFooter}>
          <Button disableRipple fullWidth className={classes.drawerFooterIcon} 
            onClick={() => drawerOpen ? setDrawerOpen(false) : setDrawerOpen(true)}>
            {drawerOpen && <ChevronLeftIcon />}
            {!drawerOpen && <ChevronRightIcon />}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    flex: '0 0 auto',
    padding: '0.75rem 1rem',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,.3)'
  },
  drawerHeaderLogo: {
    height: '45px',
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    borderRight: '0px',
    justifyContent: 'space-between',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRight: '0px',
    justifyContent: 'space-between',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  drawerList: {
    padding: '0px'
  },
  drawerFooter: {
    backgroundColor: 'rgba(0,0,0,.3)'
  },
  drawerFooterIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px 24px',
  },
  listItemText: {
    fontWeight: 'bold',
  },
}));

export default withFirebase(withAuthenticationConsumer(Main))
