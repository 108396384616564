import React, { Component, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Snackbar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'

import { withFirebase } from '../../../firebase';

const LoginForm = ({ firebase, history }) => {
  const classes = useStyles();

  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [emailField, setEmailField] = React.useState('');
  const [sentOpen, setSentOpen] = React.useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setButtonLoading(true)

    const email = emailField;
    firebase.doPasswordReset(email)
      .then(() => {
        // setSentOpen(true);
      })
      .catch(error => {
        setError(error);
      })
      .then(() => {
        setButtonLoading(false);
      })
  };

  const isValid = () => {
    if (!emailField) {
      return false
    }
    return true
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSentOpen(false);
  }

  return (
    <form className={classes.form} onSubmit={onSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={emailField}
        onChange={(e) => {setEmailField(e.target.value)}}
      />
      {/* <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      /> */}
      {error &&
        <Alert variant="outlined" severity="error">
          {error.message}
        </Alert>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isValid() || buttonLoading}
        className={classes.submit}
      >
        {buttonLoading ? 'Sending email...' : 'Reset'}
      </Button>
      <Grid container>
        <Grid item>
          <Link component={RouterLink} to="/signup">
            Back to Sign In
          </Link>
        </Grid>
      </Grid>
      <Snackbar open={sentOpen} autoHideDuration={3000} onClose={closeSnackbar}>
        <Alert variant="outlined" onClose={closeSnackbar} severity="success">
          Password reset sent
        </Alert>
      </Snackbar>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default withFirebase(LoginForm)